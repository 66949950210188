import { Paper, styled, alpha } from '@mui/material';

const StyledGlassPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 15,
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  position: 'relative',
  overflow: 'hidden',
  background: `linear-gradient(
    to bottom right,
    ${alpha(theme.palette.background.paper, 0.8)},
    ${alpha(theme.palette.background.paper, 0.4)}
  )`,
  backdropFilter: 'blur(12px)',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  boxShadow: `0 8px 32px ${alpha(theme.palette.common.black, 0.1)}`,

  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: `0 12px 40px ${alpha(theme.palette.common.black, 0.2)}`,
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '200%',
    height: '100%',
    background: `linear-gradient(
      90deg,
      transparent,
      ${alpha(theme.palette.common.white, 0.2)},
      transparent
    )`,
    transition: 'left 0.6s',
  },

  '&:hover::before': {
    left: '100%',
  },
}));

export default StyledGlassPaper;
