import React, { useState } from 'react';
import {
  Box,
  Typography,
  styled,
  Paper,
  Button,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StatsCard = styled(Paper)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: 16,
  padding: theme.spacing(3),
  margin: theme.spacing(1),
  minWidth: 250,
  minHeight: 220,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 0.3s ease',
  boxShadow: theme.shadows[2],
  '&:hover': {
    transform: 'translateY(-3px)',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    minHeight: 220,
  },
}));

const TwoButtonsCard = ({
  activityColor,
  title,
  description,
  value,
  button1Text,
  button2Text,
  onButton1Click,
  onButton2Click,
}) => {
  const theme = useTheme();
  const [selectedButton, setSelectedButton] = useState(1);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [buttons] = useState(
    [
      { id: 1, label: button1Text, action: onButton1Click },
      { id: 2, label: button2Text, action: onButton2Click },
    ].filter((btn) => btn.label && btn.action),
  );

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  return (
    <StatsCard elevation={0}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            justifyContent: 'center',
            gap: 0.5,
            mb: 2,
            position: 'relative',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              letterSpacing: 1.1,
              fontWeight: 600,
              color: theme.palette.text.secondary,
              lineHeight: 1,
              [theme.breakpoints.up('md')]: { fontSize: '0.95rem' },
              [theme.breakpoints.down('sm')]: { fontSize: '0.8rem' },
              flexShrink: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {title}
          </Typography>

          <Tooltip
            title={description}
            arrow
            open={openTooltip}
            onClose={handleTooltipClose}
            onClick={handleTooltipOpen}
            slotProps={{
              tooltip: {
                sx: {
                  bgcolor: theme.palette.background.default,
                  color: theme.palette.text.primary,
                  border: `1px solid ${theme.palette.divider}`,
                  fontSize: theme.typography.body2.fontSize,
                  maxWidth: 250,
                  [theme.breakpoints.down('sm')]: { maxWidth: 200 },
                },
              },
              arrow: { sx: { color: theme.palette.background.default } },
            }}
          >
            <IconButton
              size="medium"
              aria-label="info"
              sx={{
                p: 0.3,
                color: theme.palette.text.secondary,
                '&:hover': {
                  color: theme.palette.primary.main,
                  backgroundColor: 'transparent',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                verticalAlign: 'middle',
              }}
            >
              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  filter: 'drop-shadow(0 0 0.5px currentColor)',
                  fontSize: '1rem',
                  [theme.breakpoints.up('md')]: { fontSize: '1.2rem' },
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>

        <Typography
          variant="h1"
          sx={{
            fontWeight: 900,
            fontSize: '3rem',
            lineHeight: 1,
            color: activityColor,
            [theme.breakpoints.down('sm')]: {
              fontSize: '3rem',
            },
          }}
        >
          {value}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
            mt: 4, //buttons.length > 0 ? 2 : 0,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          {buttons.map((btn) => (
            <Button
              key={btn.id}
              fullWidth
              variant={selectedButton === btn.id ? 'contained' : 'text'}
              onClick={() => {
                setSelectedButton(btn.id);
                btn.action();
              }}
              color="primary"
              sx={{
                py: 0.5,
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 700,
                fontSize: '0.79rem',
                letterSpacing: 0.5,
                transition: 'all 0.2s ease',
                backgroundColor:
                  selectedButton === btn.id
                    ? alpha(theme.palette.primary.main, 0.9)
                    : alpha(theme.palette.primary.main, 0.1),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  boxShadow: 'none',
                },
              }}
            >
              {btn.label}
            </Button>
          ))}
        </Box>
      </Box>
    </StatsCard>
  );
};

export default TwoButtonsCard;
