import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../context/user';
import Loader from '../components/Loader';
import ErrorPage from '../components/ErrorPage';
// import SharedStatistics from '../components/SharedStatistics';
import ComingSoonPage from '../components/FutureService';

const PublicStatistics = () => {
  const navigate = useNavigate();

  const { id: statsId } = useParams();
  const { api } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [displayNotFound, setDisplayNotFound] = useState(false);
  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      const itemData = await api.item.getPublicStatistics({
        id: statsId,
      });
      if (!itemData?.statistics) {
        setDisplayNotFound(true);
      } else {
        setData(itemData);
      }
    } catch (error) {
      console.error(error);
      navigate(`/error`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (displayNotFound) {
    return <ErrorPage />;
  }

  return <ComingSoonPage data={data} />;
  // return <SharedStatistics data={data} />;
};

export default PublicStatistics;
