import React from 'react';
import { Box, Grid, Typography, Paper, Divider, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';

export default function GeneralStatsSection({
  actualValueType,
  summaryInfo,
  valueType,
}) {
  const theme = useTheme();
  const intl = useIntl();

  const recordValue =
    actualValueType === 1
      ? summaryInfo?.recordCountMark?.total?.toLocaleString()
      : parseInt(summaryInfo?.recordCountMark?.total / 60, 10).toLocaleString();

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 4, mt: 3, mb: 3 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <InfoOutlined color="primary" sx={{ fontSize: 28, mr: 1.5 }} />
        <Typography variant="h6" component="h2" fontWeight="bold">
          <FormattedMessage id="KeyMetrics" />
        </Typography>
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Grid container spacing={3}>
        {summaryInfo?.total && (
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title={<FormattedMessage id="TotalCount" />}
              value={
                actualValueType === 1
                  ? summaryInfo?.total.toLocaleString()
                  : parseInt(summaryInfo?.total / 60, 10).toLocaleString()
              }
              description={`${intl.formatMessage({ id: 'TotalCumulativeValue' })}${valueType}`}
              color={theme.palette.primary.main}
              backgroundColor={theme.palette.summaryCard}
            />
          </Grid>
        )}

        {summaryInfo?.created && (
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title={<FormattedMessage id="ActivityPeriod" />}
              value={summaryInfo?.created}
              description={<FormattedMessage id="ActivityLifetime" />}
              color={theme.palette.info.main}
              backgroundColor={theme.palette.summaryCard}
            />
          </Grid>
        )}

        {summaryInfo?.totalTarget && (
          <Grid item xs={12} sm={6} md={4}>
            <StatCard
              title={<FormattedMessage id="ForecastedResult" />}
              value={
                actualValueType === 1
                  ? summaryInfo?.totalTarget.toLocaleString()
                  : parseInt(summaryInfo?.totalTarget / 60, 10).toLocaleString()
              }
              description={`${intl.formatMessage({ id: 'ExpectedTargetMetric' })}${valueType}`}
              color={theme.palette.success.main}
              backgroundColor={theme.palette.summaryCard}
            />
          </Grid>
        )}

        {summaryInfo?.totalRecordsCount && (
          <Grid item xs={12} sm={6}>
            <StatCard
              title={<FormattedMessage id="TotalExecutions" />}
              value={summaryInfo?.totalRecordsCount?.toLocaleString()}
              description={<FormattedMessage id="TotalAttempts" />}
              color={theme.palette.warning.main}
              backgroundColor={theme.palette.summaryCard}
            />
          </Grid>
        )}

        {summaryInfo?.recordCountMark?.total &&
          summaryInfo?.recordCountMark?.count && (
            <Grid item xs={12} sm={6}>
              <StatCard
                title={<FormattedMessage id="RecordMetric" />}
                // value={`${summaryInfo?.recordCountMark?.count?.toLocaleString()} (${summaryInfo?.recordCountMark?.total?.toLocaleString()})`}
                value={`${summaryInfo?.recordCountMark?.count?.toLocaleString()} (${recordValue})`}
                description={`${intl.formatMessage({ id: 'MaxExecutionsAndTotal' })}${valueType}`}
                color={theme.palette.error.main}
                backgroundColor={theme.palette.summaryCard}
              />
            </Grid>
          )}
      </Grid>
    </Paper>
  );
}

function StatCard({ title, value, description, color, backgroundColor }) {
  return (
    <Box
      sx={{
        p: 2.5,
        borderLeft: `4px solid ${color}`,
        backgroundColor,
        borderRadius: 1,
        height: '100%',
      }}
    >
      <Typography variant="subtitle2" color="text.secondary" mb={0.5}>
        {title}
      </Typography>
      <Typography variant="h5" fontWeight="bold" mb={1.5} color={color}>
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
}
