import React, { useEffect, useState } from 'react'; // useState // useEffect,
import { Box, Typography, styled, Paper, useTheme } from '@mui/material';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { FormattedMessage, useIntl } from 'react-intl';
import TwoButtonsCard from '../TwoButtonsCard';
import getDaysByDates from '../../shared/utils/getDaysByDates';
import StatsGrid from './StatsGrid';
import TwoButtonsSmall from '../TwoButtonsSmall';
import InfoButton from '../InfoButton';
import GeneralStatsSection from './GeneralStatsSection';
import getDisplayedDays from '../../shared/utils/getDisplayedDays';
// import DonutChart from './DonutChart';
import AvgMinMaxTab from './AvgMinMaxTab';

const ActivityCard = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'data',
})(({ theme, data }) => ({
  position: 'relative',
  backgroundColor: data?.valueAssessment
    ? theme.palette.primary.main
    : theme.palette.red,
  color: theme.palette.common.white,
  borderRadius: 16,
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  maxWidth: 800,
  minHeight: 120,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

const SharedStatistics = ({ data, displayingCreatedDate, userName }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [activityColor] = useState(
    data?.itemInfo?.valueAssessment
      ? theme.palette.primary.main
      : theme.palette.red,
  );
  const [activityRate, setActivityRate] = useState('-');
  const [activityTargetRate, setActivityTargetRate] = useState('-');
  const [displayTargetActivityRate, setDisplayTargetActivityRate] =
    useState(true);
  const [actualValueType, setActualValueType] = useState(1);
  const [valueTypeInDesc, setValueTypeInDesc] = useState('');
  const [views] = useState(data?.views);
  const [summaryInfo, setSummaryInfo] = useState({});

  const totalRecordsCount = data?.totalRecordsCount;

  let defaultValueType = '';
  let secondValueType = '';
  const type = data?.itemInfo?.valueType;
  if (type === 'minutes' || type === 'seconds') {
    defaultValueType = intl.formatMessage({
      id: `${data?.itemInfo?.valueType[0].toUpperCase()}${data?.itemInfo?.valueType.slice(1)}`,
    });
    const secondValueTypeIntlId = type === 'minutes' ? 'Hours' : 'Minutes';
    secondValueType = intl.formatMessage({ id: secondValueTypeIntlId });
  }
  const totalDays = getDaysByDates(data?.statistics?.startedAt, new Date()) + 1;
  const getActivityPercentage = (daysDone, totalDays) => {
    if (!daysDone || !totalDays) return;
    const floatPercentage =
      totalDays && daysDone && (daysDone * 100) / totalDays;
    return parseInt(floatPercentage, 10);
  };

  const dynamicStats = [
    {
      value: `${data?.statistics?.dayPercentageDynamics || '-'} %`,
      label: <FormattedMessage id="Day" />,
    },
    {
      value: `${data?.statistics?.weekPercentageDynamics || '-'} %`,
      label: <FormattedMessage id="Week" />,
    },
    {
      value: `${data?.statistics?.monthPercentageDynamics || '-'} %`,
      label: <FormattedMessage id="Month" />,
    },
    {
      value: `${data?.statistics?.yearPercentageDynamics || '-'} %`,
      label: <FormattedMessage id="Year" />,
    },
  ];

  useEffect(() => {
    defaultValueType && setValueTypeInDesc(`${defaultValueType}`);
    const doneDays = data?.statistics?.daysDone;
    const activity = getActivityPercentage(doneDays, totalDays);
    setActivityRate((!!activity && activity) || '-');
    activity && setActivityRate(activity);
    const doneTargetDays = data?.statistics?.daysPerActionTargetDone;
    const targetActivity = getActivityPercentage(doneTargetDays, totalDays);
    const perActionActivity = getActivityPercentage(
      data?.statistics?.daysPerActionTargetDone,
      totalDays,
    );
    const perDayActivity = getActivityPercentage(
      data?.statistics?.daysTargetDone,
      totalDays,
    );
    setDisplayTargetActivityRate(perActionActivity && perDayActivity);
    setActivityTargetRate((!!targetActivity && targetActivity) || '-');
    const localItemCreatedDate = localStorage.getItem(
      `createdDate#${data?.itemInfo?.id}`,
    );
    const createdDays = getDaysByDates(
      new Date(localItemCreatedDate),
      new Date(),
    );
    const createdDaysFullString = getDisplayedDays(createdDays, intl);
    const [createdDisplayedDays] = createdDaysFullString.split('(');
    const possibleTargetTotal =
      !!data?.itemInfo?.target &&
      parseInt(data?.itemInfo?.target) * createdDays;
    const summary = {
      created: createdDisplayedDays,
      total: data?.statistics?.total,
      totalTarget: possibleTargetTotal,
      totalRecordsCount,
      recordCountMark: {
        total: data?.maxRecordCountObj?.total,
        count: data?.maxRecordCountObj?.count,
      },
    };
    setSummaryInfo(summary);
  }, []);

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const handlePerAction = async () => {
    const perActionActivity = getActivityPercentage(
      data?.statistics?.daysPerActionTargetDone,
      totalDays,
    );
    setActivityTargetRate((!!perActionActivity && perActionActivity) || '-');
  };

  const handlePerDay = async () => {
    const perDayActivity = getActivityPercentage(
      data?.statistics?.daysTargetDone,
      totalDays,
    );
    setActivityTargetRate((!!perDayActivity && perDayActivity) || '-');
  };

  const handleDefaultValueType = () => {
    setValueTypeInDesc(`${defaultValueType}`);
    setActualValueType(1);
  };

  const handleSecondValueType = () => {
    setValueTypeInDesc(`${secondValueType}`);
    setActualValueType(2);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 1800,
        px: 2,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          px: 2,
          position: 'relative',
        }}
      >
        {views > 0 && (
          <InfoButton
            baseColor={activityColor}
            text={views}
            tooltip={<FormattedMessage id="views" />}
          />
        )}

        <InfoButton
          baseColor={activityColor}
          text={<FormattedMessage id="Share" />}
          tooltip={<FormattedMessage id="CopyToClipboard" />}
          interactive={true}
          onClick={handleCopyUrl}
          buttonProps={{
            color: 'secondary',
            sx: { borderRadius: '8px' },
          }}
        />
      </Box>

      <ActivityCard data={data?.itemInfo} elevation={3}>
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 40,
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          {/* userName */}
          <Typography
            sx={{
              position: 'absolute',
              right: '100%',
              whiteSpace: 'nowrap',
              pr: 1,
              fontSize: '0.875rem',
              lineHeight: 1,
              letterSpacing: '0.05em',
              fontWeight: 'bold',
            }}
          >
            {userName}
          </Typography>
          {/* icon */}
          <Box
            sx={{
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {data?.itemInfo?.valueAssessment ? (
              <DataSaverOnIcon fontSize="large" />
            ) : (
              <IndeterminateCheckBoxIcon fontSize="large" />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            zIndex: 1,
            paddingTop: { xs: 4, sm: 0 },
          }}
        >
          {/* Title */}
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              [theme.breakpoints.down('sm')]: {
                fontSize: '2rem',
              },
            }}
          >
            {data?.itemInfo?.title}
          </Typography>
          {/* Description */}
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              opacity: 0.9,
              lineHeight: 1.6,
              maxWidth: '80%',
              fontWeight: 700,
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                fontSize: '0.9rem',
              },
            }}
          >
            {data?.itemInfo?.description}
          </Typography>
          {/* TODO: temp test realization component */}
          <TwoButtonsSmall
            button1Text={defaultValueType}
            button2Text={secondValueType}
            onButton1Click={handleDefaultValueType}
            onButton2Click={handleSecondValueType}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              display: 'block',
              mt: 2,
              opacity: 0.7,
              fontSize: '0.8rem',
              fontWeight: 600,
            }}
          >
            <FormattedMessage id="CreatedOn" /> {displayingCreatedDate}
          </Typography>
        </Box>
      </ActivityCard>

      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexWrap="wrap"
        gap={1}
        alignItems="center"
        justifyContent="center"
        sx={{
          transition: 'all 0.3s ease',
          '@media (min-width: 900px) and (max-width: 1200px)': {
            '& > *': {
              flex: '1 1 45%',
              minWidth: 300,
            },
          },
        }}
      >
        <TwoButtonsCard
          activityColor={activityColor}
          title={<FormattedMessage id="ActivityRate" />}
          description={<FormattedMessage id="ActivityRateDescription" />}
          value={`${activityRate} %`}
        />
        {displayTargetActivityRate && (
          <TwoButtonsCard
            activityColor={activityColor}
            title={<FormattedMessage id="SuccessRate" />}
            description={<FormattedMessage id="SuccessRateDescription" />}
            value={`${activityTargetRate} %`}
            button1Text={<FormattedMessage id="PerAction" />}
            button2Text={<FormattedMessage id="PerDay" />}
            onButton1Click={handlePerAction}
            onButton2Click={handlePerDay}
          />
        )}
      </Box>

      <StatsGrid
        activityColor={activityColor}
        title={<FormattedMessage id="ProgressDynamics" />}
        items={dynamicStats}
      />
      <AvgMinMaxTab
        actualValueType={actualValueType}
        color={activityColor}
        statistics={data?.statistics}
        valueType={valueTypeInDesc}
      />
      <GeneralStatsSection
        actualValueType={actualValueType}
        summaryInfo={summaryInfo}
        valueType={valueTypeInDesc ? `, ${valueTypeInDesc}` : ''}
      />
      {/* <DonutChart /> */}
    </Box>
  );
};

export default SharedStatistics;
