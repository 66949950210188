const getDaysByDates = (from, to) => {
  const fromDate = typeof from === 'string' ? new Date(from) : from;
  const toDate = typeof to === 'string' ? new Date(to) : to;

  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(0, 0, 0, 0);

  const diffTime = toDate - fromDate;
  const millisecondsPerDay = 1000 * 60 * 60 * 24;

  return Math.floor(diffTime / millisecondsPerDay);
};

export default getDaysByDates;
