import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  IconButton,
  Slide,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import StatsGrid from './StatsGrid';
import { FormattedMessage, useIntl } from 'react-intl';

const AvgMinMaxTab = ({ actualValueType, color, statistics, valueType }) => {
  const theme = useTheme();
  const intl = useIntl();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);

  const generateStats = (type) => {
    const periods = [
      { suffix: 'Days', labelId: 'Day' },
      { suffix: 'Weeks', labelId: 'Week' },
      { suffix: 'Months', labelId: 'Month' },
      { suffix: 'Years', labelId: 'Year' },
    ];

    return periods.map(({ suffix, labelId }) => ({
      value:
        actualValueType === 1
          ? statistics?.[`${type}Of${suffix}`]
          : parseInt(statistics?.[`${type}Of${suffix}`] / 60, 10),
      label: <FormattedMessage id={labelId} />,
    }));
  };
  const avgStats = generateStats('avg');
  const maxStats = generateStats('max');
  const minStats = generateStats('min');
  const titles = [
    `${intl.formatMessage({ id: 'Average' })}`,
    `${intl.formatMessage({ id: 'Maximum' })}`,
    `${intl.formatMessage({ id: 'Minimum' })}`,
  ];
  const handleMenuSelect = (index) => {
    setValue(index);
    setAnchorEl(null);
  };

  return (
    <Paper elevation={3} sx={{ borderRadius: 4, overflow: 'hidden' }}>
      {isMobile ? (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontWeight: 800,
              px: 2,
              py: 1.5,
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box>{titles[value]}</Box>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MenuIcon />
            </IconButton>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {titles.map((label, index) => (
              <MenuItem
                key={label}
                onClick={() => handleMenuSelect(index)}
                selected={value === index}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Tabs
          value={value}
          onChange={(e, newVal) => setValue(newVal)}
          variant="scrollable"
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            '& .MuiTabs-indicator': {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          {titles.map((label) => (
            <Tab
              key={label}
              label={label}
              sx={{
                fontWeight: 800,
                minWidth: 100,
                '&.Mui-selected': { color: theme.palette.primary.dark },
              }}
            />
          ))}
        </Tabs>
      )}

      <Box sx={{ p: 2, minHeight: 200 }}>
        <TabPanel value={value} index={0} isMobile={isMobile}>
          <StatsGrid activityColor={color} items={avgStats} title={valueType} />
        </TabPanel>

        <TabPanel value={value} index={1} isMobile={isMobile}>
          <StatsGrid activityColor={color} items={maxStats} title={valueType} />
        </TabPanel>

        <TabPanel value={value} index={2} isMobile={isMobile}>
          <StatsGrid activityColor={color} items={minStats} title={valueType} />
        </TabPanel>
      </Box>
    </Paper>
  );
};

function TabPanel({ children, value, index, isMobile }) {
  return (
    <Slide
      in={value === index}
      direction={isMobile ? 'up' : 'left'}
      timeout={200}
    >
      <div hidden={value !== index}>
        <Box sx={{ p: 1 }}>{children}</Box>
      </div>
    </Slide>
  );
}

export default AvgMinMaxTab;
