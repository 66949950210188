const formatDateByLocale = (incomingDate, locale, time = false) => {
  const datePart = incomingDate.toISOString().split('T')[0];
  let date;
  if (!time) {
    date = new Date(`${datePart}T00:00:00Z`);
  } else {
    date = incomingDate;
  }

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  if (time) {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.hourCycle = 'h23';
    options.timeZone = 'UTC';
  }
  return new Intl.DateTimeFormat(locale, options).format(date);
};

export default formatDateByLocale;
