/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';

import { IconButton } from '@mui/material';

import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

import { Grid, Typography } from '@mui/material';

import Input from '../components/Input/Input.jsx';

const DELAY = 150;

const EditValue = ({ type, value, setValue }) => {
  const [longPressMultiplier, setLongPressMultiplier] = useState(1);

  let actualValue;
  let additionalValue;

  if (type !== 'minutes' && type !== 'seconds') {
    actualValue = value;
  } else {
    actualValue = Math.floor(value / 60);
    additionalValue = value % 60;
  }

  const getOneAndTen = (event) => {
    const id = event.currentTarget.id;
    const one = id === 'decrement' || id === 'additionalDecrement' ? -1 : 1;
    const ten = id === 'decrement' || id === 'additionalDecrement' ? -10 : 10;
    return { id, one, ten };
  };

  const countIncrement = (prevCount) => (secondTerm) =>
    prevCount + secondTerm <= 0
      ? additionalCount > 0
        ? 0
        : 1
      : prevCount + secondTerm;

  const additionalCountIncrement = (prevCount) => (secondTerm) =>
    prevCount + secondTerm <= 0
      ? count > 0
        ? 0
        : 1
      : prevCount + secondTerm >= 60
        ? 59
        : prevCount + secondTerm;

  const [count, setCount] = useState(actualValue);
  const [additionalCount, setAdditionalCount] = useState(additionalValue);
  const timeoutRef = useRef(null);
  const timeoutExtraRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      clearInterval(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (type !== 'minutes' && type !== 'seconds') {
      setValue(count);
    } else {
      const resultValue = count * 60 + additionalCount;
      setValue(resultValue);
    }
  }, [additionalCount, count, setValue, type]);

  const handleContextMenu = (event) => event.preventDefault();

  const clearTimer = () => {
    clearTimeout(timeoutRef.current);
    clearInterval(timeoutRef.current);
    clearTimeout(timeoutExtraRef.current);
    clearInterval(timeoutExtraRef.current);
  };
  const handleMouseUp = () => clearTimer();
  const handleTouchEnd = () => clearTimer();

  const handlePress = (id, one) => {
    setLongPressMultiplier(1);
    if (['increment', 'decrement'].includes(id)) {
      setCount(countIncrement(one));
    } else {
      setAdditionalCount(additionalCountIncrement(one));
    }
  };

  const handleLongPress = (ten) => {
    timeoutRef.current = setInterval(() => {
      setCount(countIncrement(ten * longPressMultiplier));
      setLongPressMultiplier((prevMultiplier) => prevMultiplier + 1);
    }, DELAY);
  };

  const handleLongPressExtra = (ten) => {
    timeoutRef.current = setInterval(() => {
      setAdditionalCount(additionalCountIncrement(ten * longPressMultiplier));
      setLongPressMultiplier((prevMultiplier) => prevMultiplier + 1);
    }, DELAY);
  };

  const handleMouseDown = (event) => {
    const { id, one, ten } = getOneAndTen(event);
    handlePress(id, one);

    if (['increment', 'decrement'].includes(id)) {
      timeoutRef.current = setTimeout(() => handleLongPress(ten), DELAY);
    } else {
      timeoutExtraRef.current = setTimeout(
        () => handleLongPressExtra(ten),
        DELAY,
      );
    }
  };

  const handleTouchStart = (event) => {
    const { id, ten } = getOneAndTen(event);
    if (['increment', 'decrement'].includes(id)) {
      timeoutRef.current = setTimeout(() => handleLongPress(ten), DELAY);
    } else {
      timeoutExtraRef.current = setTimeout(
        () => handleLongPressExtra(ten),
        DELAY,
      );
    }
  };

  const ItemType = ({ align, title }) => {
    return (
      <Grid item xs={4} textAlign={align}>
        <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Grid>
    );
  };

  const handleInputChange = (event) => setValue(event.target.value);

  if (type === 'text') {
    return (
      <Grid container textAlign={'center'}>
        <Grid item xs={2} />
        <Grid item xs={8} sx={{ mt: 2 }}>
          <Input value={value} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={2} />
      </Grid>
    );
  }

  if (type === 'number') {
    return (
      <Grid container spacing={2} textAlign={'center'}>
        <Grid item xs={12}>
          <IconButton
            id="increment"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={handleContextMenu}
          >
            <ArrowDropUp style={{ fontSize: '48px' }} />
          </IconButton>
          <Typography variant="h3" style={{ margin: '0 20px' }}>
            {count}
          </Typography>
          <IconButton
            id="decrement"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={handleContextMenu}
          >
            <ArrowDropDown style={{ fontSize: '48px' }} />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  if (type === 'minutes' || type === 'seconds') {
    const leftTitle =
      type === 'minutes' ? (
        <FormattedMessage id="Hours" />
      ) : (
        <FormattedMessage id="Minutes" />
      );
    const rightTitle =
      type === 'minutes' ? (
        <FormattedMessage id="Minutes" />
      ) : (
        <FormattedMessage id="Seconds" />
      );

    return (
      <Grid container spacing={2} textAlign={'center'}>
        <Grid item xs={2} />
        <ItemType align={'right'} title={leftTitle} />
        <ItemType align={'left'} title={rightTitle} />
        <Grid item xs={2} />

        <Grid item xs={2} />
        <Grid item xs={4} textAlign={'right'} sx={{ paddingRight: '10px' }}>
          <IconButton
            id="increment"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={handleContextMenu}
          >
            <ArrowDropUp style={{ fontSize: '48px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} textAlign={'left'} style={{ paddingLeft: '35px' }}>
          <IconButton
            id="additionalIncrement"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={handleContextMenu}
          >
            <ArrowDropUp style={{ fontSize: '48px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={2} />
        <Grid item xs={4} textAlign={'right'}>
          <Typography
            variant="h3"
            style={{ paddingRight: '8px', margin: '0 20px' }}
          >
            {count}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={'left'}>
          <Typography
            variant="h3"
            style={{ paddingLeft: '8px', margin: '0 20px' }}
          >
            {additionalCount}
          </Typography>
        </Grid>
        <Grid item xs={2} />

        <Grid item xs={2} />
        <Grid item xs={4} textAlign={'right'} sx={{ paddingRight: '10px' }}>
          <IconButton
            id="decrement"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={handleContextMenu}
          >
            <ArrowDropDown style={{ fontSize: '48px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} textAlign={'left'} style={{ paddingLeft: '35px' }}>
          <IconButton
            id="additionalDecrement"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onContextMenu={handleContextMenu}
          >
            <ArrowDropDown style={{ fontSize: '48px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    );
  }
};

export default EditValue;
