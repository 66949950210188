import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

const StatsGrid = ({ activityColor, title, items }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: 2,
        mb: 6,
        m: 3,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        bgcolor: theme.palette.card,
      }}
    >
      {title && (
        <Typography
          variant="h6"
          sx={{
            mb: 3,
            fontSize: 14,
            fontWeight: 600,
            color: activityColor,
            textAlign: { xs: 'center', sm: 'center' },
          }}
        >
          {title}
        </Typography>
      )}

      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Box
              sx={{
                width: '100%',
                minWidth: 100,
                maxHeight: 100,
                aspectRatio: '1/1',
                bgcolor: theme.palette.background.paper,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                boxShadow: 2,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: 'text.primary',
                  textAlign: 'center',
                  fontWeight: 500,
                  lineHeight: 1.2,
                }}
              >
                {item.label}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 400,
                  color: activityColor,
                  lineHeight: 1,
                  fontSize: 'clamp(1rem, 5vw, 2.5rem)',
                  textAlign: 'center',
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {item.value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StatsGrid;
