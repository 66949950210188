import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    card: '#cad2d2',
    primary: {
      main: '#00703c',
    },
    secondary: {
      main: '#18c615bd',
    },
    max: '#00b211',
    maxLight: '#1bd100',
    min: '#d83900',
    minLight: '#919301',
    red: '#a50002',
    premium: '#002aff',
    summaryCard: '#f5f5f5',
    over: '#00703c',
    undone: '#5b5b5b',
  },
});

export default lightTheme;
