import React from 'react';
import {
  Container,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';

const ErrorPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container
        component="main"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 6,
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            maxWidth: 600,
            px: isMobile ? 2 : 4,
          }}
        >
          <WarningAmber
            sx={{
              fontSize: isMobile ? 64 : 80,
              color: theme.palette.warning.main,
              mb: 3,
            }}
          />
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 700,
              color: theme.palette.text.primary,
              mb: 2,
            }}
          >
            <FormattedMessage id="StatisticsUnavailable" />
          </Typography>
          <Typography
            variant={isMobile ? 'body1' : 'h6'}
            component="p"
            sx={{
              color: theme.palette.text.secondary,
              lineHeight: 1.6,
              mb: 4,
            }}
          >
            <FormattedMessage id="TheInformationMayHaveBeenDeleted" />
          </Typography>
        </Box>
      </Container>

      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="body2" color="text.secondary" align="center">
            <FormattedMessage id="Source" />:{' '}
            <a
              href={process.env.REACT_APP_HOST}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {process.env.REACT_APP_HOST}
            </a>
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default ErrorPage;
