import React, { useState } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

const TwoButtonsSmall = ({
  button1Text,
  button2Text,
  onButton1Click,
  onButton2Click,
}) => {
  const theme = useTheme();
  const [selectedButton, setSelectedButton] = useState(1);
  const [buttons] = useState(
    [
      { id: 1, label: button1Text, action: onButton1Click },
      { id: 2, label: button2Text, action: onButton2Click },
    ].filter((btn) => btn.label && btn.action),
  );

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        width: '100%',
        mt: 2,
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      }}
    >
      {buttons.map((btn) => (
        <Button
          key={btn.id}
          fullWidth
          variant={selectedButton === btn.id ? 'text' : 'contained'}
          onClick={() => {
            setSelectedButton(btn.id);
            btn.action();
          }}
          color="primary"
          sx={{
            py: 0.5,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '0.79rem',
            letterSpacing: 0.5,
            transition: 'all 0.2s ease',
            backgroundColor:
              selectedButton === btn.id
                ? alpha(theme.palette.card, 0.3)
                : alpha(theme.palette.card, 0),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
              boxShadow: 'none',
            },
            color: 'white',
          }}
        >
          {btn.label}
        </Button>
      ))}
    </Box>
  );
};

export default TwoButtonsSmall;
