import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CssBaseline,
  useTheme,
  alpha,
  styled,
} from '@mui/material';
import { Mail, ColorLens, Feedback } from '@mui/icons-material';
import StyledGlassPaper from './StyledGlassPaper';
import { FormattedMessage } from 'react-intl';
import SharedStatistics from './Statistics/Shared.jsx';
import { UserContext } from '../context/user.js';
import formatDateByLocale from '../shared/utils/formatDateByLocale.js';

const UpdateTime = styled(Typography)(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
}));

export default function ComingSoonPage({ data }) {
  const { api } = useContext(UserContext);
  const [displayingCreatedDate, setDisplayingCreatedDate] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const [userName, setUserName] = useState('');
  const [reload] = useState(
    localStorage.getItem('reload') || localStorage.setItem('reload', false),
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const { user } = await api.user.getPublicDetails({
          clientId: data?.itemInfo.userId,
        });
        if (user?.locale) {
          localStorage.setItem('selectedLocale', user.locale);
        }
        const locale = localStorage.getItem('selectedLocale');
        const [username] = user.email.split('@');
        setUserName(username);
        setLastUpdated(
          formatDateByLocale(new Date(user?.updatedDetailsAt), locale, true),
        );
        const { details: itemDetails } = await api.item.getPublicDetails({
          itemId: data?.itemInfo.id,
        });
        const [, createdItemTime] = itemDetails.createdAt.split('T');
        const zeroTimeString = '00:00:00.000Z';
        setDisplayingCreatedDate(
          formatDateByLocale(
            new Date(itemDetails.createdAt),
            locale,
            createdItemTime === zeroTimeString ? false : true,
          ),
        );
        localStorage.setItem(
          `createdDate#${data?.itemInfo?.id}`,
          itemDetails?.createdAt,
        );
        if (!reload) {
          localStorage.setItem('reload', true);
          window.location.reload();
        }
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <CssBaseline />
      <Box
        minHeight="100vh"
        py={1}
        sx={{
          background:
            theme.palette.mode === 'light'
              ? `linear-gradient(160deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[100]} 100%)`
              : `linear-gradient(160deg, ${theme.palette.grey[900]} 0%, ${theme.palette.grey[800]} 100%)`,
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={11} textAlign={'right'}>
            <UpdateTime variant="subtitle2">
              <FormattedMessage id="LatestUpdate" /> {lastUpdated}
            </UpdateTime>
          </Grid>

          <Grid item xs={12}>
            <SharedStatistics
              data={data}
              displayingCreatedDate={displayingCreatedDate}
              userName={userName}
            />
          </Grid>

          {/* Under Development sections */}
          <Grid item xs={12}>
            <Box textAlign="center" mb={6}>
              <ColorLens
                sx={{
                  mt: 20,
                  fontSize: 80,
                  color: 'primary.main',
                  filter: 'drop-shadow(0 4px 12px rgba(0,0,0,0.1))',
                }}
              />
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mt: 2,
                }}
              >
                {<FormattedMessage id="Statistics" />}{' '}
                {<FormattedMessage id="stillUnderDevelopment" />}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <StyledGlassPaper elevation={0}>
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    fontSize: '1.1rem',
                    lineHeight: 1.7,
                    color: theme.palette.text.secondary,
                  }}
                >
                  <FormattedMessage id="WeAreCreatingInteractiveStatistics" />
                </Typography>

                <Box
                  sx={{
                    bgcolor: alpha(theme.palette.primary.light, 0.2),
                    p: 2,
                    borderRadius: 2,
                    mt: 3,
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5,
                    }}
                  >
                    <Feedback sx={{ fontSize: 20 }} />
                    <span>
                      <FormattedMessage id="KeyMetricsToDisplay" />
                    </span>
                  </Typography>
                </Box>
              </Box>
            </StyledGlassPaper>
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledGlassPaper elevation={0}>
              <Box
                textAlign="center"
                sx={{
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Mail
                  sx={{
                    fontSize: 40,
                    color: 'primary.main',
                    filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.1))',
                  }}
                />
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    mt: 1,
                    fontWeight: 500,
                  }}
                >
                  <FormattedMessage id="YourOpinionMatters" />
                </Typography>
                <Typography
                  paragraph
                  sx={{
                    color: theme.palette.text.secondary,
                    mb: 3,
                  }}
                >
                  <FormattedMessage id="LetCreateUserfriendlyStatistics" />
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<Mail />}
                  href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                  sx={{
                    borderRadius: 50,
                    px: 4,
                    py: 1,
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: `0 4px 15px ${alpha(theme.palette.primary.main, 0.4)}`,
                    },
                  }}
                >
                  <FormattedMessage id="SuggestIdea" />
                </Button>
              </Box>
            </StyledGlassPaper>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                mt: 6,
                textAlign: 'center',
                opacity: 0.7,
                transition: 'opacity 0.3s',
                '&:hover': { opacity: 1 },
              }}
            >
              <Typography
                variant="caption"
                component="a"
                href={process.env.REACT_APP_HOST}
                target="_blank"
                rel="noopener"
                sx={{
                  color: theme.palette.text.secondary,
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <FormattedMessage id="Source" />: {process.env.REACT_APP_HOST}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
