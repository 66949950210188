import React, { useState } from 'react';
import { Button, Tooltip, lighten } from '@mui/material';
import { styled } from '@mui/material/styles';

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '.MuiTooltip-tooltip': {
    fontSize: '1.2rem',
    padding: theme.spacing(1.5),
  },
}));

const GradientButton = styled(Button)(({ base_color }) => ({
  minWidth: '48px',
  height: '48px',
  padding: '0 24px',
  borderRadius: '12px',
  fontSize: '1.1rem',
  fontWeight: 600,
  letterSpacing: '0.5px',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  background: `linear-gradient(45deg, ${base_color} 0%, ${lighten(
    base_color,
    0.2,
  )} 100%)`,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  color: 'white',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 6px 12px rgba(${hexToRgb(base_color)}, 0.4)`,
  },
  '&.Mui-disabled': {
    background: `linear-gradient(45deg, ${lighten(
      base_color,
      0.6,
    )} 0%, ${lighten(base_color, 0.7)} 100%)`,
    color: 'rgba(255, 255, 255, 0.9)',
    pointerEvents: 'auto',
  },
}));

const InfoButton = ({
  baseColor,
  text,
  tooltip = '',
  interactive = false,
  onClick,
  buttonProps = {},
  tooltipProps = {},
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleAction = () => {
    if (interactive && onClick) {
      onClick();
      setIsDisabled(true);
    }
  };

  return (
    <StyledTooltip title={tooltip} arrow placement="top" {...tooltipProps}>
      <span>
        {' '}
        <GradientButton
          base_color={baseColor}
          variant="contained"
          disabled={!interactive || isDisabled}
          onClick={handleAction}
          {...buttonProps}
          sx={{
            ...buttonProps.sx,
            width: 'auto',
            maxWidth: '90%',
            m: 0.5,
            ...(text.length > 6 && {
              padding: '0 32px',
              fontSize: '1.2rem',
            }),
          }}
        >
          <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
        </GradientButton>
      </span>
    </StyledTooltip>
  );
};

export default InfoButton;
